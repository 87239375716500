@if (user$ | async; as user) {
    <button type="button" (click)="onUserClick()">
        <app-icon icon="user" [size]="24" />
        {{ 'menu.mobile.panel' | translate }}
    </button>
} @else {
    <button type="button" (click)="onOpenLogin()">
        <app-icon icon="user" [size]="24" />
        {{ 'menu.mobile.login' | translate }}
    </button>
}
