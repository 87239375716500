<app-box type="logo">
    <ul>
        <li>
            <app-auth />
        </li>

        @for (item of menu; track $index) {
            <li>
                @if (item.link) {
                    <a class="item" [href]="item.link" target="_blank">
                        {{ item.title | translate }}
                    </a>
                }
            </li>
        }
    </ul>
</app-box>
