import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/v2/shared/components';

@Component({
    selector: 'app-register-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent],
})
export class InfoComponent {}
