import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { AlertsModule } from '@app/shared/alerts';
import { CurrencyModule } from '@app/currency';
import { DirectivesModule } from '@app/shared/directives';

import { ShellComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TickerComponent } from './components/ticker/ticker.component';
import { IconsModule } from '@app/shared/icons/icons.module';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule, AlertsModule, CurrencyModule, DirectivesModule, IconsModule],
    declarations: [ShellComponent, HeaderComponent, FooterComponent, TickerComponent],
})
export class ShellModule {}
