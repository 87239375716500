<div class="box">
    <div class="header">
        @if (type === 'logo') {
            <a class="logo" routerLink="/">
                <img src="assets/images/logo-66cc33.svg" />
            </a>
        } @else {
            <button
                app-button
                type="button"
                [routerLink]="['..']"
                [content]="'btn.back' | translate"
                size="sm"
                color="gray-600"
                iconLeft="chevron-left"
                appearence="text"
            ></button>
        }

        <app-button-icon class="tw-ml-auto" [closeButton]="true" icon="x-mark" (click)="onCloseClick()" />
    </div>
    <div class="content">
        <div>
            <ng-content />
        </div>
    </div>
</div>
