import { CanMatchFn } from '@angular/router';
import { environment } from '@env/environment';

const canEnterV2: CanMatchFn = () => {
    if (environment.designV2 === true) {
        return true;
    }
    return localStorage.getItem('design') === 'v2';
};

const canEnterV1: CanMatchFn = () => {
    if (environment.designV2 === false) {
        return localStorage.getItem('design') !== 'v2';
    }
    return false;
};

export { canEnterV1, canEnterV2 };
