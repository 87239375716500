import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, of, concatMap, withLatestFrom, catchError, exhaustMap, map, filter } from 'rxjs';
import { maintenanceActions } from '../actions/maintenance.actions';
import { MaintenanceState } from '../reducers/maintenance.reducer';
import { HttpService } from '../services/http.service';
import { selectMaintenanceError, selectMaintenance } from '../selectors/maintenance.selector';
import { Time } from '@app/shared';

@Injectable()
export class MaintenanceEffects {
    getMaintenance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(maintenanceActions.getAction),
            exhaustMap(() => {
                return this.httpService.maintenance().pipe(
                    map((data) => maintenanceActions.getSuccessAction({ value: data })),
                    catchError(() => of(maintenanceActions.getErrorAction())),
                );
            }),
        );
    });

    getMaintenanceSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(maintenanceActions.getSuccessAction));
        },
        {
            dispatch: false,
        },
    );

    getMaintenanceError$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(maintenanceActions.getErrorAction));
        },
        {
            dispatch: false,
        },
    );

    getMaintenanceInit$ = createEffect(() => {
        return of(environment.maintenance.interval * 1000).pipe(map(() => maintenanceActions.getAction({ timeout: true })));
    });

    getMaintenanceInterval$ = createEffect(() => {
        return interval(environment.maintenance.interval * 1000).pipe(map(() => maintenanceActions.getAction({ timeout: false })));
    });

    checkIntervalMaintenanceUnitl$ = createEffect(() => {
        return interval(1000).pipe(
            concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectMaintenance)))),
            map(([, store]) => store),
            filter(
                (data) => data !== null && data.status === true && ['desktop', 'mobileDesktop'].includes(data.type) && Time.isGreater(this.nowUTC(), data.date),
            ),
            map(() => maintenanceActions.getAction({ timeout: false })),
        );
    });

    checkIntervalErrorEnableUnitl$ = createEffect(() => {
        return interval(1000).pipe(
            concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectMaintenanceError)))),
            map(([, store]) => store),
            filter((data) => data !== null && data.status === true && Time.isGreater(new Date(), data.date)),
            map(() => maintenanceActions.disableAction()),
        );
    });

    private nowUTC = function (): Date {
        const date = new Date();
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
    };

    constructor(
        private store: Store<MaintenanceState>,
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
