import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MAINTENANCE_FEATURE_KEY } from '../keys';
import { MaintenanceState } from '../reducers/maintenance.reducer';
import { Time } from '@app/shared';

const selectState = createFeatureSelector<MaintenanceState>(MAINTENANCE_FEATURE_KEY);

export const selectMaintenance = createSelector(selectState, (state: MaintenanceState) => {
    return state.maintenance;
});

export const selectLoaded = createSelector(selectState, (state: MaintenanceState) => {
    return state.loaded;
});

export const selectLoading = createSelector(selectState, (state: MaintenanceState) => {
    return state.loading;
});

export const selectMaintenanceError = createSelector(selectState, (state: MaintenanceState) => {
    return state.maintenanceError;
});

export const selectMaintenanceEnabled = createSelector(selectState, (state: MaintenanceState) => {
    const maintenanceEnabled = state.maintenance
        ? state.maintenance.status === true &&
          ['desktop', 'mobileDesktop'].includes(state.maintenance.type) &&
          Time.isGreater(state.maintenance.date, new Date())
        : false;

    const maintenanceError = state.maintenanceError.status === true && Time.isGreater(state.maintenanceError.date, new Date());

    return maintenanceEnabled === true || maintenanceError === true;
});
