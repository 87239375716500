import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { MessageModel } from '../models';
import { TickerState } from '../reducers/ticker.reducer';
import { selectLoaded, selectLoading, selectMessages } from '../selectors/ticker.selector';
import { tickerActions } from '../actions/ticker.actions';

@Injectable({
    providedIn: 'root',
})
export class TickerFacade {
    messages$ = this.store.select(selectMessages).pipe(filter((messages): messages is MessageModel[] => messages !== null));
    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);

    constructor(private store: Store<TickerState>) {}

    clear(): void {
        this.store.dispatch(tickerActions.clearAction());
    }
}
