import { environment } from '@env/environment';

const nav = [
    {
        id: '1',
        name: 'nav.how-to-book',
        href: `${environment.mainPageUrl}jak-zarezerwowac-przejazd/`,
    },
    {
        id: '2',
        name: 'nav.security',
        href: 'https://gtvbuspro.pl/bezpieczenstwo',
    },
    {
        id: '3',
        name: 'nav.luggage',
        href: `${environment.mainPageUrl}bagaz/`,
    },
];

export { nav };
