<app-popup [title]="'account.register.title' | translate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-form-input class="tw-mb-4" controlName="email" [label]="'account.login.form.login' | translate" [autocomplete]="'username'" />

        <app-form-input
            class="tw-mb-9"
            controlName="password"
            type="password"
            [preview]="true"
            [label]="'account.login.form.password' | translate"
            [autocomplete]="'new-password'"
        />

        <ng-container formGroupName="consent">
            <app-form-checkbox class="tw-mb-2" controlName="termsOfService">
                {{ 'regulations.checkbox.accept' | translate }}
                <a class="tw-underline" target="_blank" appRegulations>{{ 'regulations.checkbox.accept.regulation' | translate }}</a>
                {{ 'regulations.checkbox.accept.and' | translate }}
                <a class="tw-underline" target="_blank" appPrivacyPolicy>{{ 'regulations.checkbox.accept.privacy-policy' | translate }}</a>
                {{ 'regulations.checkbox.accept.service' | translate }}
            </app-form-checkbox>

            <app-form-checkbox class="tw-mb-2" controlName="marketing">
                @if ((option1Expand$ | async) === true) {
                    {{ 'regulations.checkbox.marketing.1.long' | translate }}
                    <a class="tw-underline" (click)="toggleExpand1Click($event)" href="#">{{ 'button.collapse' | translate }}</a>
                } @else {
                    {{ 'regulations.checkbox.marketing.1.short' | translate }}
                    <a class="tw-underline" (click)="toggleExpand1Click($event)" href="#">{{ 'button.expand' | translate }}</a>
                }
            </app-form-checkbox>

            <app-form-checkbox class="tw-mb-9" controlName="telemarketing">
                @if ((option2Expand$ | async) === true) {
                    {{ 'regulations.checkbox.marketing.2.long' | translate }}
                    <a class="tw-underline" (click)="toggleExpand2Click($event)" href="#">{{ 'button.collapse' | translate }}</a>
                } @else {
                    {{ 'regulations.checkbox.marketing.2.short' | translate }}
                    <a class="tw-underline" (click)="toggleExpand2Click($event)" href="#">{{ 'button.expand' | translate }}</a>
                }
            </app-form-checkbox>
        </ng-container>

        <div class="tw-flex tw-justify-center tw-mb-10">
            <button
                app-button
                type="submit"
                size="md"
                [content]="'btn.register' | translate"
                [spinner]="spinner$ | async"
                [disabled]="spinner$ | async"
            ></button>
        </div>

        <div class="tw-text-center">
            <app-button-link
                [text]="'account.register.already-registered' | translate"
                [linkText]="'account.register.form.login-now' | translate"
                [link]="['..', 'login']"
            />
        </div>
    </form>
</app-popup>
