<header class="header bg-white shadow-1">
    <div class="container">
        <div class="d-flex align-items-center h-100 py-8 px-lg-0 px-5">
            <div class="me-14 me-lg-18">
                <a class="unstyled" routerLink="/home" [skipLocationChange]="true">
                    <img class="logo d-block" src="assets/images/logo-66cc33.svg" />
                </a>
            </div>

            <div class="me-16 d-none d-lg-inline-block">
                <span class="icon icon-7 icon-success me-4" appIcon="envelope"></span>
                <a class="text-gray-500 fs-13" href="mailto:info@gtvbus.pl">info&#64;gtvbus.pl</a>
            </div>

            <div class="me-lg-16 me-8 ms-auto ms-lg-0 d-none d-lg-block">
                <span class="icon icon-7 icon-success me-4" appIcon="phone"></span>
                <a class="text-gray-500 fs-13 d-none d-lg-inline-block" href="tel:+48606200002">+48 606 200 002</a>
            </div>

            <nav class="ms-auto d-flex">
                <div class="pe-8 me-8 border-end border-gray-200 d-none d-lg-block">
                    <a class="text-gray-600 fs-13 fw-700" target="_blank" href="https://gtvbus.pl/o-nas/bezpieczenstwo" translate>header.nav.safety</a>
                </div>
                <div class="me-16 d-none d-lg-block">
                    <a class="text-gray-600 fs-13 fw-700" target="_blank" [href]="faqLink$ | async" translate>header.nav.help</a>
                </div>
                <div class="me-8">
                    <app-currency-menu></app-currency-menu>
                </div>
                <div class="me-8 d-block d-lg-none">
                    <a href="tel:+48606200002" class="icon icon-7 icon-gray-600 me-4" appIcon="phone"></a>
                </div>
                <div>
                    <a class="icon icon-7 icon-gray-600" routerLink="/user" href="" appIcon="user"></a>
                </div>
                <!-- <div>
                    <a class="icon icon-7 icon-gray-600" routerLink="/" href="" appIcon="cart"></a>
                </div> -->
            </nav>
        </div>
    </div>
</header>
