import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, timer, map, startWith, Subscription, take, tap } from 'rxjs';
import { Time } from '@app/shared';
import { Grow } from '@app/shared/animations';
import { TickerFacade, MessageModel } from '@app/store/ticker';
import { environment } from '@env/environment';

const SKIP_URLS = ['/booking/passenger', '/booking', '/home'];

@Component({
    selector: 'app-ticker',
    templateUrl: './ticker.component.html',
    styleUrls: ['./ticker.component.scss'],
    animations: [Grow],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickerComponent implements OnInit, OnDestroy {
    hidden$ = new BehaviorSubject<boolean>(true);
    interval$ = timer(0, environment.ticker.interval.dateCheck * 1000);

    messagesFiltered$ = combineLatest([this.tickerFacade.messages$.pipe(map((messages) => this.filterMessages(messages))), this.interval$]).pipe(
        map(([messages]) => messages),
        distinctUntilChanged(),
    );

    messages$ = combineLatest([this.hidden$, this.messagesFiltered$]).pipe(map(([hidden, messages]) => (hidden ? [] : messages)));

    private subscriptions$ = new Subscription();

    constructor(private tickerFacade: TickerFacade, private router: Router) {}

    ngOnInit() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    map((event) => event.url),
                    startWith(this.router.url),
                    filter((url) => !SKIP_URLS.includes(url)),
                    tap(() => this.hidden$.next(false)),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private filterMessages(messages: MessageModel[]) {
        const now = Time.now();
        return messages
            .filter((message) => message.active)
            .filter((message) => {
                if (message.activeFrom && Time.isGreater(Time.stringToTime(message.activeFrom), now)) {
                    return false;
                }
                if (message.activeUntil && Time.isGreater(now, Time.stringToTime(message.activeUntil))) {
                    return false;
                }
                return true;
            });
    }
}
