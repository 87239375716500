import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountFacade } from '@app/store/account';
import { Subscription, take, tap } from 'rxjs';

@Component({
    selector: 'app-account-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class AccountActivateComponent implements OnInit, OnDestroy {
    private readonly accountFacade = inject(AccountFacade);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    readonly activated$ = this.accountFacade.activated$;

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        const token = this.activatedRoute.snapshot.paramMap.get('token');

        if (token) {
            this.accountFacade.activate(token);

            this.subscriptions$.add(
                this.activated$
                    .pipe(
                        take(1),
                        tap(() => this.router.navigate(['../..'])),
                    )
                    .subscribe(),
            );
        } else {
            this.router.navigate(['../..']);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
