<footer class="footer bg-white shadow-1-reverse">
    <div class="border-bottom border-gray-200">
        <div class="container">
            <div class="d-lg-flex align-items-center px-lg-0 px-8">
                <nav class="d-flex flex-wrap py-lg-14 py-8">
                    <div class="pe-8 me-8 border-end border-gray-200">
                        <a class="text-gray-600 fs-13 fw-700" target="_blank" href="https://gtvbus.pl/start/agencje-pracy" translate
                            >footer.nav.for-companies</a
                        >
                    </div>
                    <div class="pe-8 me-8 border-end border-gray-200">
                        <a class="text-gray-600 fs-13 fw-700" target="_blank" href="https://gtvbus.pl/wideo-jak-zarezerwowac-przejazd" translate
                            >footer.nav.how-to-buy</a
                        >
                    </div>
                    <div class="pe-8 me-8 border-end border-gray-200">
                        <a class="text-gray-600 fs-13 fw-700" target="_blank" href="https://gtvbus.pl/o-nas/oferty-pracy-w-gtv-bus" translate
                            >footer.nav.recruitment</a
                        >
                    </div>
                    <div class="pe-8 me-8 border-end border-gray-200">
                        <a class="text-gray-600 fs-13 fw-700" target="_blank" appPrivacyPolicy translate>footer.nav.privacy-policy</a>
                    </div>
                    <div>
                        <a class="text-gray-600 fs-13 fw-700" target="_blank" appRegulations translate>footer.nav.regulations</a>
                    </div>
                </nav>

                <nav class="ms-auto d-lg-flex align-items-center py-lg-17 pb-10">
                    <div class="me-12 fs-13 mb-lg-0 mb-4" translate>footer.social-media</div>
                    <div class="d-flex">
                        <a class="icon icon-7 icon-gray-600 me-12" target="_blank" appFacebook translate appIcon="sm-facebook"></a>
                        <a class="icon icon-7 icon-gray-600 me-12" target="_blank" href="https://www.instagram.com/gtv_bus" appIcon="sm-instagram"></a>
                        <a
                            class="icon icon-7 icon-gray-600 me-12"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCDOWG6chr0egOYMIN0eQh0g"
                            appIcon="sm-youtube"
                        ></a>
                    </div>
                </nav>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="d-lg-flex align-items-center py-14">
            <div class="fs-13 mb-lg-0 mb-4" translate>footer.copyright</div>
            <div class="ms-auto fs-13">
                {{ 'footer.powered-by' | translate }} <a href="https://codelabs.rocks/" target="_blank" class="fw-700 text-gray-600">CODELABS</a>
            </div>
        </div>
    </div>
    <div class="version text-end">
        <div (click)="v2()">v{{ version.time }}</div>
    </div>
</footer>
