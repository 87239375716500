import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MaintenanceEffects } from './effects/maintenance.effects';
import { maintenanceReducer } from './reducers/maintenance.reducer';
import { MAINTENANCE_FEATURE_KEY } from './keys';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(MAINTENANCE_FEATURE_KEY, maintenanceReducer), EffectsModule.forFeature([MaintenanceEffects])],
})
export class MaintenanceStoreModule {}
