import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { tickerReducer } from './reducers/ticker.reducer';
import { TickerEffects } from './effects/ticker.effects';
import { TICKER_FEATURE_KEY } from './keys';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(TICKER_FEATURE_KEY, tickerReducer), EffectsModule.forFeature([TickerEffects])],
})
export class TickerStoreModule {}
