import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { catchError, exhaustMap, map, startWith } from 'rxjs/operators';
import { tickerActions } from '../actions/ticker.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class TickerEffects {
    get$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tickerActions.getAction),
            exhaustMap(() => {
                return this.httpService.messages().pipe(
                    map((data) => tickerActions.getSuccessAction({ value: data })),
                    catchError(() => of(tickerActions.getErrorAction())),
                );
            }),
        );
    });

    getSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(tickerActions.getSuccessAction));
        },
        {
            dispatch: false,
        },
    );

    getError$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(tickerActions.getErrorAction));
        },
        {
            dispatch: false,
        },
    );

    getInterval$ = createEffect(() => {
        return interval(environment.ticker.interval.request * 1000).pipe(
            startWith(0),
            map(() => tickerActions.getAction()),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
