import { environment } from '@env/environment';
import { createReducer, on } from '@ngrx/store';
import { maintenanceActions } from '../actions/maintenance.actions';
import { MaintenanceErrorModel, MaintenanceModel } from '../models';

export interface MaintenanceState {
    maintenance: MaintenanceModel | null;
    maintenanceError: MaintenanceErrorModel;
    loaded: boolean | null;
    loading: boolean | null;
}

export const initialState: MaintenanceState = {
    maintenance: null,
    maintenanceError: {
        date: new Date(),
        status: false,
    },
    loaded: false,
    loading: false,
};

export const maintenanceReducer = createReducer(
    initialState,
    on(
        maintenanceActions.getAction,
        (state): MaintenanceState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        maintenanceActions.getSuccessAction,
        (state, payload): MaintenanceState => ({
            ...state,
            maintenance: payload.value,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        maintenanceActions.getErrorAction,
        (state): MaintenanceState => ({
            ...state,
            maintenance: {
                type: '',
                message: '',
                date: new Date(),
                status: false,
            },
            loaded: false,
            loading: false,
        }),
    ),
    on(
        maintenanceActions.enableAction,
        (state): MaintenanceState => ({
            ...state,
            maintenanceError: {
                date: new Date(new Date().getTime() + environment.maintenance.onErrorRetryAfter * 1000),
                status: true,
            },
        }),
    ),
    on(
        maintenanceActions.disableAction,
        (state): MaintenanceState => ({
            ...state,
            maintenanceError: {
                date: new Date(),
                status: false,
            },
        }),
    ),
    on(
        maintenanceActions.clearAction,
        (state): MaintenanceState => ({
            ...state,
            ...initialState,
        }),
    ),
);
