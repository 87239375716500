import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BoxComponent } from '../../shared/components';
import { environment } from '@env/environment';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@app/v2/shared/components';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, BoxComponent, IconComponent],
})
export class ScheduleComponent {
    readonly menu = environment.mobile.menu;
}
