import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { navFooter } from '../../../config/nav-footer.config';

@Component({
    selector: 'app-nav-footer',
    templateUrl: './nav-footer.component.html',
    styleUrls: ['./nav-footer.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class NavFooterComponent {
    readonly nav = navFooter;
}
