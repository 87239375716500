import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { CURRENCY } from '@currency';

import { IconComponent } from '@app/v2/shared/components';
import { BoxComponent } from '../../shared/components';
import { CurrencyService } from '@app/currency';
import { LanguageService } from '@app/i18n';
import { map } from 'rxjs';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, BoxComponent, IconComponent],
})
export class LanguageComponent {
    private currencyService = inject(CurrencyService);
    private languageService = inject(LanguageService);

    readonly currency$ = this.currencyService.currency$;
    readonly language$ = this.languageService.language$.pipe(map((language) => language.split('-')[0]));

    readonly currencies = this.currencyService.currencies;
    readonly languages = environment.supportedLanguages;

    onLanguageClick(lang: string) {
        this.languageService.set(lang);
    }

    onCurrencyClick(lang: CURRENCY) {
        this.currencyService.set(lang);
    }

    get lang() {
        return this.languageService.get();
    }

    flag(value: string | number): string {
        return value.toString().split('-')[0];
    }

    // .map((language) => ({
    //     name: <string>this.translateService.instant(`language.${language.key}`),
    //     value: language.key,
    // }));
}

// currencies = environment.currency.available.map((currency) => ({
//     name: currency,
//     value: currency,
// }));

// lanugages = environment.supportedLanguages.map((language) => ({
//     name: <string>this.translateService.instant(`language.${language.key}`),
//     value: language.key,
// }));
