import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { IconComponent } from '@app/v2/shared/components';

import { BoxComponent, AuthComponent, LangaugeItemComponent } from '../../shared/components';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, BoxComponent, TranslateModule, IconComponent, AuthComponent, LangaugeItemComponent],
})
export class MenuComponent {
    readonly menu = environment.mobile.menu;
}
