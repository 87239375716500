<nav>
    @if (user$ | async) {
        <ul>
            <li>
                <app-button-icon [icon]="'user'" routerLink="/user" />
            </li>
        </ul>
    } @else {
        <ul>
            <li>
                <button class="tw-text-[0.875rem] tw-leading-[1rem] tw-font-bold" (click)="onOpenLogin()">{{ 'header.shop.auth.login' | translate }}</button>
            </li>
            <li>
                <button class="tw-text-[0.875rem] tw-leading-[1rem] tw-font-bold" (click)="onOpenRegister()">
                    {{ 'header.shop.auth.register' | translate }}
                </button>
            </li>
        </ul>
    }
</nav>
