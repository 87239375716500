<app-popup [title]="'account.register.success.title' | translate">
    <div class="sm:tw-mb-9 tw-mb-8 info">
        <div class="tw-text-sm tw-font-normal">
            {{ 'account.register.success.description' | translate }}<span class="tw-font-semibold">{{ email }}</span>
        </div>
    </div>

    <div class="tw-flex tw-justify-center">
        <button (click)="onCloseClick()" app-button type="button" size="md" [content]="'btn.close' | translate"></button>
    </div>
</app-popup>
