<app-popup [title]="'account.reset-password.title' | translate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-form-input
            class="tw-mb-9"
            controlName="password"
            [label]="'account.reset-password.form.password' | translate"
            type="password"
            [autocomplete]="'new-password'"
        />

        <div class="tw-flex tw-justify-center">
            <button
                app-button
                type="submit"
                size="md"
                [content]="'btn.set-password' | translate"
                [spinner]="spinner$ | async"
                [disabled]="spinner$ | async"
            ></button>
        </div>
    </form>
</app-popup>
