import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MaintenanceFacade } from '../store/maintenance';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceDisabledGuard {
    constructor(
        private maintenanceFacade: MaintenanceFacade,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.maintenanceFacade.enabled$.pipe(
            take(1),
            map((enabled) => {
                if (enabled === false) {
                    return this.router.createUrlTree(['/']);
                }
                return true;
            }),
        );
    }
}
