<app-popup [title]="'user.logout.confirmation.title' | translate">
    <div class="box-grid">
        <button
            app-button
            type="button"
            [block]="true"
            [content]="'user.logout.confirmation.button.no' | translate"
            (click)="onCloseClick()"
            appearence="outline"
        ></button>
        <button app-button type="button" [block]="true" [content]="'user.logout.confirmation.button.yes' | translate" (click)="onLogoutClick()"></button>
    </div>
</app-popup>
