import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceContainerComponent } from './container/container.component';
import { MaintenanceStoreModule } from './store/maintenance';

@NgModule({
    imports: [CommonModule, MaintenanceStoreModule],
    declarations: [MaintenanceContainerComponent],
    exports: [MaintenanceContainerComponent],
})
export class MaintenanceModule {}
