import { Component } from '@angular/core';
import { nav } from '../../../config/nav.config';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class NavComponent {
    readonly nav = nav;
}
