import { createAction, props } from '@ngrx/store';
import { MessageModel } from '../models';

export enum TickerActionTypes {
    GET_ACTION = '[Ticker] Get action',
    GET_SUCCESS_ACTION = '[Ticker] Get success action',
    GET_ERROR_ACTION = '[Ticker] Get error action',
    CLEAR_ACTION = '[Ticker] Clear',
}

const getAction = createAction(TickerActionTypes.GET_ACTION);
const getSuccessAction = createAction(TickerActionTypes.GET_SUCCESS_ACTION, props<{ value: MessageModel[] }>());
const getErrorAction = createAction(TickerActionTypes.GET_ERROR_ACTION);
const clearAction = createAction(TickerActionTypes.CLEAR_ACTION);

export const tickerActions = {
    getAction,
    getSuccessAction,
    getErrorAction,
    clearAction,
};
