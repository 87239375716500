import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { MaintenanceFacade } from '../store/maintenance';

@Injectable({
    providedIn: 'root',
})
export class ApiMaintenanceErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private maintenanceFacade: MaintenanceFacade) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<unknown>> {
        this.errorCodes(response['status']);
        throw response;
    }

    private errorCodes(code: number) {
        switch (code) {
            case 0:
                this.maintenanceFacade.enable();
                break;
            case 504:
                this.maintenanceFacade.enable();
                break;
        }
    }
}
