import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/service/auth.service';
import { AlertsService } from '@app/shared/alerts';
import { Router } from '@angular/router';
import { CleanupService } from '@app/store/services/cleanup.service';

/**
 *  Call refrest token request on 401 error
 */
@Injectable({
    providedIn: 'root',
})
export class ApiTokenRefreshInterceptor implements HttpInterceptor {
    private inflightAuthRequest: null | Observable<Request>;

    constructor(
        private injector: Injector,
        private alertsService: AlertsService,
        private router: Router,
        private cleanupService: CleanupService,
    ) {
        this.inflightAuthRequest = null;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    return this.refreshTokenRequest(next, request);
                }
                return throwError(() => error);
            }),
        );
    }

    /**
     * Token Refresh process
     */
    private refreshTokenRequest(next: HttpHandler, authReq: HttpRequest<unknown>) {
        // const translateService = this.injector.get(TranslateService);
        let authReqRepeat: HttpRequest<unknown> | null = null;
        return this.inflightAuth.pipe(
            switchMap(() => {
                this.clearInflightAuth();
                authReqRepeat = authReq.clone();
                return next.handle(authReqRepeat);
            }),
            catchError((error: HttpErrorResponse) => {
                // show message
                if (error.status === 401) {
                    this.alertsService.show('auth.token.expired', 'danger');
                    this.cleanupService.cleanup();
                    void this.router.navigate(['/']);
                }
                // remove inflight auth request
                this.clearInflightAuth();
                return throwError(() => error);
            }),
        );
    }

    /**
     * Get Inflight Auth
     */
    private get inflightAuth(): Observable<Request> {
        const authService = this.injector.get(AuthService);
        if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = authService.refresh();
        }
        return this.inflightAuthRequest;
    }

    /**
     * Clear inflightAuthRequest
     */
    private clearInflightAuth() {
        this.inflightAuthRequest = null;
    }
}
