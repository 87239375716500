import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContainerComponent } from './container/container.component';
import { MenuComponent } from './components/menu/menu.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { LanguageComponent } from './components/language/language.component';
import { bodyLockGuard, bodyUnlockGuard } from '../shared/guards';

const routes: Routes = [
    {
        path: 'menu',
        component: ContainerComponent,
        outlet: 'mobile',
        canActivate: [bodyLockGuard()],
        canDeactivate: [bodyUnlockGuard()],
        children: [
            {
                path: '',
                component: MenuComponent,
                data: {
                    animation: '1',
                },
            },
            {
                path: 'local-timetable',
                component: ScheduleComponent,
                data: {
                    animation: '2',
                },
            },
            {
                path: 'language',
                component: LanguageComponent,
                data: {
                    animation: '3',
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MobileMenuRoutingModule {}
