import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownItem } from '@app/shared/forms/models';
import { BehaviorSubject, Subscription, Observable, map, startWith, debounceTime, distinctUntilChanged } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/v2/shared/components';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Grow } from '@app/v2/shared/animations';
import { DropdownListComponent } from '../dropdown-list/dropdown-list.component';

@Component({
    selector: 'app-dropdown',
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent, DropdownListComponent],
    templateUrl: './dropdown.component.html',
    animations: [Grow],
    styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit, OnDestroy {
    private readonly controlContainer = inject(ControlContainer);

    @ViewChild('focusEl') focusEl!: ElementRef;
    @ViewChild('blurEl') blurEl!: ElementRef;

    @Input({ required: true }) controlName!: string;

    @Input() options: DropdownItem[] = [];
    @Input() type: 'default' | 'language' = 'default';

    readonly _focus$ = new BehaviorSubject(false);
    readonly focus$ = this._focus$.pipe(debounceTime(1), distinctUntilChanged());
    readonly open$ = new BehaviorSubject<boolean>(false);

    fc!: FormControl<string>;
    value$!: Observable<string>;
    name$!: Observable<string>;

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);

        this.name$ = this.fc.valueChanges.pipe(
            startWith(this.fc.getRawValue()),
            map((value) => this.options?.find((option) => option.value === value)?.name ?? ''),
        );

        this.value$ = this.fc.valueChanges.pipe(startWith(this.fc.getRawValue()));
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onFocusIn() {
        this._focus$.next(true);
    }

    onFocusOut() {
        this._focus$.next(false);
    }

    getFlagUrl(value: string | null): string {
        return `url('assets/svgs/optimized-flags/${(value ?? 'default').toLowerCase().split('-')[0]}.svg')`;
    }

    onSelectEvent(value: string | number) {
        if (typeof value === 'string') {
            this.fc.patchValue(value);
        }

        this.focusEl.nativeElement.focus();
        this.focusEl.nativeElement.blur();
        this.blurEl.nativeElement.focus();
    }
}
