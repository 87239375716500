<app-box type="back">
    <h4>{{ 'mobile.menu.item.local-timetable.title' | translate }}</h4>
    <ul>
        @for (item of menu; track $index; let first = $first) {
            <li>
                <a class="item" [class.first]="first" [href]="item.link" target="_blank">
                    {{ item.title | translate }}
                </a>
            </li>
        }
    </ul>
</app-box>
