import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TICKER_FEATURE_KEY } from '../keys';
import { TickerState } from '../reducers/ticker.reducer';

const selectState = createFeatureSelector<TickerState>(TICKER_FEATURE_KEY);

export const selectMessages = createSelector(selectState, (state: TickerState) => {
    return state.messages;
});

export const selectLoaded = createSelector(selectState, (state: TickerState) => {
    return state.loaded;
});

export const selectLoading = createSelector(selectState, (state: TickerState) => {
    return state.loading;
});
