import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { maintenanceActions } from '../actions/maintenance.actions';
import { MaintenanceModel } from '../models';
import { selectMaintenance, selectMaintenanceEnabled } from '../selectors/maintenance.selector';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceFacade {
    private readonly store = inject(Store);

    maintenance$ = this.store.select(selectMaintenance).pipe(filter((data): data is MaintenanceModel => data !== null));
    enabled$ = this.store.select(selectMaintenanceEnabled);

    enable() {
        this.store.dispatch(maintenanceActions.enableAction());
    }

    disable() {
        this.store.dispatch(maintenanceActions.enableAction());
    }

    clear(): void {
        this.store.dispatch(maintenanceActions.clearAction());
    }
}
