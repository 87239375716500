<div class="tw-flex tw-gap-x-4">
    <a href="https://www.linkedin.com/company/gtv-bus" target="_blank">
        <app-icon class="tw-block" [size]="42" icon="social-in" color="gray-600" />
    </a>
    <a appFacebook target="_blank" translate href="#">
        <app-icon class="tw-block" [size]="42" icon="social-fb" color="gray-600" />
    </a>
    <a href="https://www.youtube.com/channel/UCDOWG6chr0egOYMIN0eQh0g" target="_blank">
        <app-icon class="tw-block" [size]="42" icon="social-yt" color="gray-600" />
    </a>
</div>
