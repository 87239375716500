import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsService } from '@app/shared/alerts';

@Injectable({
    providedIn: 'root',
})
export class ApiErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertsService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<unknown>> {
        this.errorCodes(response['status']);
        throw response;
    }

    private errorCodes(code: number) {
        switch (code) {
            case 0:
                break;
            case 401:
                // Refresh Token Interceptor handles this
                break;
            case 403:
                // do nothing
                break;
            case 404:
                this.alertService.show('alert.error.generic.not-found', 'danger', true);
                break;
            default: {
                this.alertService.show('alert.error.generic', 'danger', true);
                break;
            }
        }
    }
}
