import { Component } from '@angular/core';
import { LanguageService } from '@app/i18n';
import { environment } from '@env/environment';
import { map } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    faqLink$ = this.languageService.language$.pipe(map((key) => environment.supportedLanguages.find((language) => language.key === key)?.faq));

    constructor(private languageService: LanguageService) {}

    clickLogin(event: MouseEvent) {
        event.preventDefault();
    }
}
