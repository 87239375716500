import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserPreloadGuard } from '@app/shared/guards';
import { MaintenanceContainerComponent, MaintenanceDisabledGuard, MaintenanceEnabledGuard } from './maintenance';
import { ShellComponent } from './shell/components/shell/shell.component';

import { ShellComponent as V2ShellComponent } from './v2/shell/components/shell/shell.component';
import { canEnterV1, canEnterV2 } from './core/guards';
import { parcelPaymentRedirect } from './redirects';
import { orderCleanup } from './store/order';
import { orderSegmentCleanup } from './store/order-segment';
import { showSettings, hideSettings } from './v2/shell/shared/guards';
import { countriesLoaded } from './store/countries';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [MaintenanceEnabledGuard],
        children: [
            {
                path: '',
                canActivate: [countriesLoaded()],
                children: [
                    {
                        path: '',
                        redirectTo: 'booking',
                        pathMatch: 'full',
                    },
                    {
                        path: 'widget',
                        loadChildren: () => import('./widget/widget.module').then((m) => m.WidgetModule),
                    },
                    {
                        path: '',
                        component: ShellComponent,
                        canMatch: [canEnterV1],
                        canActivate: [UserPreloadGuard],
                        children: [
                            {
                                path: 'home',
                                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
                            },
                            {
                                path: 'account',
                                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
                            },
                            {
                                path: 'booking',
                                loadChildren: () => import('./booking-search/booking-search.module').then((m) => m.BookingSearchModule),
                            },
                            {
                                path: 'booking/closest',
                                loadChildren: () => import('./booking-closest/booking-closest.module').then((m) => m.BookingClosestModule),
                            },
                            {
                                path: 'booking/details',
                                canDeactivate: [orderCleanup()],
                                loadChildren: () => import('./booking-details/booking-details.module').then((m) => m.BookingDetailsModule),
                            },
                            {
                                path: 'booking/parcel/payment',
                                loadChildren: () =>
                                    import('./booking-package-payment/booking-package-payment.module').then((m) => m.BookingPackagePaymentModule),
                            },
                            {
                                path: 'booking/confirmation',
                                loadChildren: () => import('./booking-confirmation/booking-confirmation.module').then((m) => m.BookingConfirmationModule),
                            },
                            {
                                path: 'r',
                                loadChildren: () => import('./remarketing/remarketing.module').then((m) => m.RemarketingModule),
                            },
                            {
                                path: 'user',
                                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
                            },
                        ],
                    },
                    {
                        path: '',
                        component: V2ShellComponent,
                        canMatch: [canEnterV2],
                        canActivate: [UserPreloadGuard],
                        children: [
                            {
                                path: 'home',
                                pathMatch: 'full',
                                redirectTo: '',
                            },
                            {
                                path: '',
                                loadChildren: () => import('./v2/home/home.module').then((m) => m.HomeModule),
                            },
                            {
                                path: 'booking/passenger',
                                loadChildren: () => import('./v2/booking/booking.module').then((m) => m.BookingModule),
                            },
                            {
                                path: 'booking/details',
                                canActivate: [hideSettings()],
                                canDeactivate: [orderCleanup(), showSettings()],
                                loadChildren: () => import('./v2/booking-details/booking-details.module').then((m) => m.BookingDetailsModule),
                            },
                            {
                                path: 'booking/confirmation',
                                canDeactivate: [orderSegmentCleanup()],
                                loadChildren: () => import('./v2/booking-response/booking-response.module').then((m) => m.BookingResponseModule),
                            },
                            {
                                path: 'booking/parcel/payment/:bookingId/:secret',
                                redirectTo: parcelPaymentRedirect,
                            },
                            {
                                path: 'r',
                                loadChildren: () => import('./remarketing/remarketing.module').then((m) => m.RemarketingModule),
                            },
                            {
                                path: 'user',
                                loadChildren: () => import('./v2/user/user.module').then((m) => m.UserModule),
                            },
                            {
                                path: 'booking/closest',
                                loadChildren: () => import('./v2/booking-closest/booking-closest.module').then((m) => m.BookingClosestModule),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'maintenance',
        component: MaintenanceContainerComponent,
        canActivate: [MaintenanceDisabledGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
