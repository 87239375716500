import { trigger, transition, animate, style } from '@angular/animations';

export const ShowHide = trigger('ShowHide', [
    transition(':enter', [
        style({
            opacity: 0,
        }),
        animate(
            '0.3s cubic-bezier(0.25, 0, 0.1, 1)',
            style({
                opacity: 1,
            }),
        ),
    ]),
    transition(':leave', [
        style({
            position: 'absolute',
            top: '0',
            right: '0',
        }),
        animate('0.3s cubic-bezier(0.25, 0, 0.1, 1)', style({ opacity: 0 })),
    ]),
]);
