import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared';
import { map, Observable } from 'rxjs';
import { MessageModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    messages(): Observable<MessageModel[]> {
        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .skipToken()
            .skipRefreshToken()
            .get<ApiResponse<MessageModel[]>>(`api/notification`)
            .pipe(map((response) => response.data));
    }
}
