import { createAction, props } from '@ngrx/store';
import { MaintenanceModel } from '../models';

export enum MaintenanceActionTypes {
    GET_ACTION = '[Maintenance] Get action',
    GET_SUCCESS_ACTION = '[Maintenance] Get success action',
    GET_ERROR_ACTION = '[Maintenance] Get error action',
    ENABLE_ACTION = '[Maintenance] Enable action',
    DISABLE_ACTION = '[Maintenance] Disable action',
    CLEAR_ACTION = '[Maintenance] Clear',
}

const getAction = createAction(MaintenanceActionTypes.GET_ACTION, props<{ timeout: boolean }>());
const getSuccessAction = createAction(MaintenanceActionTypes.GET_SUCCESS_ACTION, props<{ value: MaintenanceModel }>());
const getErrorAction = createAction(MaintenanceActionTypes.GET_ERROR_ACTION);
const enableAction = createAction(MaintenanceActionTypes.ENABLE_ACTION);
const disableAction = createAction(MaintenanceActionTypes.DISABLE_ACTION);
const clearAction = createAction(MaintenanceActionTypes.CLEAR_ACTION);

export const maintenanceActions = {
    getAction,
    getSuccessAction,
    getErrorAction,
    enableAction,
    disableAction,
    clearAction,
};
