import { trigger, transition, animate, style, query, group, animateChild } from '@angular/animations';

export const openClose = trigger('openClose', [
    transition(
        ':enter',
        group([
            style({ opacity: 0 }),
            animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 1 })),
            query('@routeAnimation', animateChild(), { optional: true }),
        ]),
    ),
    transition(
        ':leave',
        group([
            style({ opacity: 1 }),
            animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 0 })),
            query('@routeAnimation', animateChild(), { optional: true }),
        ]),
    ),
]);

export const routeAnimation = trigger('routeAnimation', [
    transition('* <=> *', [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }),
            ],
            { optional: true },
        ),
        query(':enter', [style({ transform: 'translateX(-100%)', opacity: 0 })], { optional: true }),
        query(':leave', [style({ transform: 'translateX(0)', opacity: 1 })], { optional: true }),
        group([
            query(':enter', [animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ transform: 'translateX(0)', opacity: 1 }))], { optional: true }),
            query(':leave', [animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({ transform: 'translateX(-100%)', opacity: 0 }))], { optional: true }),
        ]),
    ]),
]);
