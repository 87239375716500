import { environment } from '@env/environment';

const navFooter = [
    {
        id: '1',
        name: 'nav.faq',
        href: `${environment.mainPageUrl}faq/`,
    },
    {
        id: '2',
        name: 'nav.contact',
        href: `${environment.mainPageUrl}kontakt/`,
    },
];

export { navFooter };
