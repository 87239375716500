import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { AlertsService } from '@app/shared/alerts';
import { MaintenanceFacade } from '../store/maintenance';

@Component({
    selector: 'app-maintenance-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceContainerComponent implements OnDestroy {
    private readonly maintenanceFacade = inject(MaintenanceFacade);
    private readonly alertsService = inject(AlertsService);

    readonly maintenance$ = this.maintenanceFacade.maintenance$;

    ngOnDestroy(): void {
        this.alertsService.clear();
    }
}
