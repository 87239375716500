import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserFacade } from '@app/store/user';
import { ButtonComponent, ButtonIconComponent, IconComponent } from '@app/v2/shared/components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, ButtonComponent, ButtonIconComponent, IconComponent],
})
export class AuthComponent {
    private readonly router = inject(Router);
    private readonly userFacade = inject(UserFacade);

    readonly loaded$ = this.userFacade.loaded$;
    readonly user$ = this.userFacade.user$;

    onOpenLogin() {
        this.router.navigate([{ outlets: { modal: 'account/login', mobile: null } }]);
    }

    onUserClick() {
        this.router.navigateByUrl('/user/settings');
    }
}
