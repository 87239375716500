import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { MaintenanceFacade } from '../store/maintenance';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {
    private readonly maintenanceFacade = inject(MaintenanceFacade);
    private readonly router = inject(Router);
    private readonly location = inject(Location);

    savedUrl = this.location.path() === '/maintenance' ? '/' : this.location.path();

    init(): void {
        this.maintenanceFacade.enabled$
            .pipe(
                tap((enabled) => {
                    if (enabled && this.router.url !== '/maintenance') {
                        this.savedUrl = this.location.path() === '/maintenance' ? '/' : this.location.path();
                        this.router.navigateByUrl('/maintenance', { skipLocationChange: true });
                    } else if (this.router.url === '/maintenance') {
                        this.router.navigateByUrl(this.savedUrl);
                    }
                }),
            )
            .subscribe();
    }
}
