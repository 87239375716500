import { Time } from '@app/shared';

export class MaintenanceModel {
    type: string;
    message: string;
    date: Date;
    status: boolean;
    constructor(data: MaintenanceDto) {
        this.type = data && data.type ? data.type : '';
        this.message = data && data.message ? data.message : '';
        this.date = data && data.date ? stringToDate(data.date) : new Date(0);
        this.status =
            data && data.status && data.status === true && Time.isGreater(data && data.date ? stringToDate(data.date) : new Date(0), nowUTC()) ? true : false;
    }
}

export interface MaintenanceErrorModel {
    date: Date;
    status: boolean;
}

export interface MaintenanceDto {
    type: string;
    message: string;
    date: string;
    status: boolean;
}

const stringToDate = function (date: string): Date {
    const dateRaw = date.split(' ')[0].split('-');
    const timeRaw = date.split(' ')[1].split(':');
    return new Date(Date.UTC(+dateRaw[0], +dateRaw[1] - 1, +dateRaw[2], +timeRaw[0], +timeRaw[1], +timeRaw[2]));
};

const nowUTC = function (): Date {
    const date = new Date();
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
};
