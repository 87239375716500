import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MaintenanceDto, MaintenanceModel } from '../models';
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    maintenance(): Observable<MaintenanceModel> {
        return this.httpClient
            .addMaintenance()
            .get<MaintenanceDto>('index.html')
            .pipe(map((response) => new MaintenanceModel(response)));
    }
}
