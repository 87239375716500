import { createReducer, on } from '@ngrx/store';
import { tickerActions } from '../actions/ticker.actions';
import { MessageModel } from '../models';

export interface TickerState {
    messages: MessageModel[] | null;
    loaded: boolean | null;
    loading: boolean | null;
}

export const initialState: TickerState = {
    messages: null,
    loaded: false,
    loading: false,
};

export const tickerReducer = createReducer(
    initialState,
    on(
        tickerActions.getAction,
        (state): TickerState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        tickerActions.getSuccessAction,
        (state, payload): TickerState => ({
            ...state,
            messages: payload.value,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        tickerActions.getErrorAction,
        (state): TickerState => ({
            ...state,
            loaded: false,
            loading: false,
        }),
    ),
    on(
        tickerActions.clearAction,
        (state): TickerState => ({
            ...state,
            ...initialState,
        }),
    ),
);
