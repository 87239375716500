import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

export interface RequestCacheEntry {
    url: string;
    response: HttpResponse<unknown>;
    date: number;
}

const maxAge = 60000;

@Injectable({
    providedIn: 'root',
})
export class RequestCacheService {
    private cache = new Map<string, RequestCacheEntry>();

    get(req: HttpRequest<unknown>): HttpResponse<unknown> | undefined {
        const url = req.urlWithParams;
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }

        const isExpired = cached.date < Date.now() - maxAge;

        return isExpired ? undefined : cached.response;
    }

    put(req: HttpRequest<unknown>, response: HttpResponse<unknown>): void {
        const url = req.urlWithParams;

        const entry: RequestCacheEntry = { url, response, date: Date.now() };
        this.cache.set(url, entry);

        this.cleanup();
    }

    private cleanup() {
        const expired = Date.now() - maxAge;
        this.cache.forEach((entry) => {
            if (entry.date < expired) {
                this.cache.delete(entry.url);
            }
        });
    }
}
