<div class="title tw-mb-10">{{ 'account.register.why-join' | translate }}</div>

<ul class="grid-list">
    <li>
        <div class="object">
            <app-icon icon="approved-user" color="white" [size]="42" />
        </div>

        <div>
            <h6 class="tw-mb-1">{{ 'account.login.register.list.1' | translate }}</h6>
            <p>{{ 'account.login.register.list.description.1' | translate }}</p>
        </div>
    </li>

    <li>
        <div class="object">
            <app-icon icon="sale" color="white" [size]="42" />
        </div>

        <div>
            <h6 class="tw-mb-1">{{ 'account.login.register.list.2' | translate }}</h6>
            <p>{{ 'account.login.register.list.description.2' | translate }}</p>
        </div>
    </li>

    <li>
        <div class="object">
            <app-icon icon="list" color="white" [size]="42" />
        </div>

        <div>
            <h6 class="tw-mb-1">{{ 'account.login.register.list.3' | translate }}</h6>
            <p>{{ 'account.login.register.list.description.3' | translate }}</p>
        </div>
    </li>
</ul>
