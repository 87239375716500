<app-ticker />
<app-alerts />
<app-header />

<section>
    <router-outlet />
</section>

<app-footer />

<router-outlet name="modal" />
