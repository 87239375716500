import { Component } from '@angular/core';
import { build } from '../../../../../build';
import { environment } from '@env/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    readonly version = <{ tag: string; time: string }>build;
    readonly environment = environment;
}
