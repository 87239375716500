import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable, delay, of, tap } from 'rxjs';
import { RequestCacheService } from './api-cache.service';

@Injectable({
    providedIn: 'root',
})
export class ApiCacheInterceptor implements HttpInterceptor {
    private readonly requestCacheService = inject(RequestCacheService);

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        const cachedResponse = this.requestCacheService.get(req);

        return cachedResponse ? of(cachedResponse).pipe(delay(240)) : this.sendRequest(req, next);
    }

    private sendRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    this.requestCacheService.put(req, event);
                }
            }),
        );
    }
}
