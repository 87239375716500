import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, inject } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, Router, RouterModule } from '@angular/router';
import { ButtonIconComponent } from '@app/v2/shared/components';
import { openClose, routeAnimation } from '../animations';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    animations: [openClose, routeAnimation],
    standalone: true,
    imports: [CommonModule, RouterModule, ButtonIconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent {
    private contexts = inject(ChildrenOutletContexts);
    private elementRef = inject(ElementRef);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);

    @HostBinding('@openClose') get(): void {
        console.log('openClose');
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(): void {
        this.close();
    }

    @HostListener('click', ['$event'])
    onHostClick(e: PointerEvent) {
        if (this.elementRef.nativeElement === e.target) {
            this.close();
        }
    }

    getRouteAnimationData() {
        return this.contexts.getContext('primary')?.route?.snapshot.url[0]?.path;
    }

    onCloseClick() {
        this.close();
    }

    private close() {
        this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    }
}
